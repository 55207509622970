import { Injectable } from '@angular/core';
import { ImageFieldImage, LinkField, RichTextField } from '@prismicio/types';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrismicService } from './prismic.service';

const NAVIGATION_DATA = 'navigation';

@Injectable()
export class NavigationDataService {
  cachedData?: NavigationData;

  constructor(private prismicService: PrismicService) {}

  getNavigationData() {
    const graphQuery = `
    {
      navigation {
        ...navigationFields
      }
    }
    `;

    return from(this.prismicService.client.getByType(NAVIGATION_DATA, { graphQuery })).pipe(
      map((queryResponse) => {
        return this.handleNavigationData(queryResponse.results?.[0]?.data as PrismicData);
      })
    );
  }

  // private handlePageData(data: Record<string, DynamicPage>): object {
  private handleNavigationData(data: PrismicData): NavigationData {
    this.cachedData = {
      navbar: {
        ...data
      },
      footer: {
        ...data
      }
    };
    return this.cachedData;
  }
}

export interface NavigationData {
  navbar: Navbar;
  footer: Footer;
}

export interface Navbar {
  logo: ImageFieldImage;
  buttons: {
    anchor_tag?: string;
    button_text: string;
    link: LinkField;
    button_type: boolean;
  }[];
}

export interface NavbarButton {
  anchor_tag?: string;
  button_text: RichTextField;
  link: LinkField;
}

export interface Footer {
  bottom_links: {
    anchor_tag?: string;
    button_text: string;
    link: LinkField;
  }[];
  contact: NavbarButton[];
  contact_title: string;
  footer_buttons: NavbarButton[];
  opening_hours: {
    day: string;
    hours: string;
  }[];
  opening_hours_notes: RichTextField;
  opening_hours_title: string;
  section_title: string;
}

export interface PrismicData {
  buttons: any;
  logo: ImageFieldImage;
  bottom_links: {
    anchor_tag?: string;
    button_text: string;
    link: LinkField;
  }[];
  contact: NavbarButton[];
  contact_title: string;
  footer_buttons: NavbarButton[];
  opening_hours: {
    day: string;
    hours: string;
  }[];
  opening_hours_notes: RichTextField;
  opening_hours_title: string;
  section_title: string;
}

export enum ButtonType {
  default = 'Default',
  primary = 'Primary',
  secondary = 'Secondary'
}

import { Injectable } from '@angular/core';
import { BuildQueryURLArgs, predicate, QueryParams } from '@prismicio/client';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrismicService } from './prismic.service';

const NEWS = 'news';

@Injectable()
export class NewsService {
  constructor(private prismicService: PrismicService) {}

  getNewsItems(pageSize?: number): Observable<any[]> {
    const graphQuery = `
    {
      ${NEWS} {
        date
        title
        summary
        content
        image
      }
    }
    `;

    const queryArgs: Partial<BuildQueryURLArgs> = {
      // @ts-ignore
      predicates: [predicate.not('document.tags', ['hidden'])],
      orderings: {
        field: `my.${NEWS}.date`,
        direction: 'desc'
      },
      graphQuery
    };

    if (pageSize) {
      queryArgs.pageSize = pageSize;
    }

    return from(this.prismicService.client.getAllByType(NEWS, queryArgs)).pipe(
      map((response) => {
        return response.map((item) => item.data);
      })
    );
  }

  getNewsItemById(uid: string): Observable<any | undefined> {
    const graphQuery = `
    {
      ${NEWS} {
        ...articleFields
      }
    }
    `;

    const queryArgs: QueryParams = {
      graphQuery
    };

    return from(this.prismicService.client.getByUID(NEWS, uid, queryArgs));
  }

  handleBlogItemSummary(article: any): BlogItemSummary {
    return {
      uid: article.uid,
      titleText: article.data.title.text(),
      image: article.data.article_image?.url,
      thumbnail: article.data.article_image?.Thumbnail?.url,
      statement: article.data.statement
    };
  }

  private getReadingTime(slices: any): number {
    // const textItems = slices
    //   .map((slice) => BlogService.getTexts(slice))
    //   .flat(1)
    //   .map((item) => item.text)
    //   .reduce((item1, item2) => item1 + ' ' + item2);
    //
    // return Math.round(BlogService.handleTextContent(textItems));
    return 0;
  }
}

export interface BlogItemSummary {
  uid: string;
  titleText?: string;
  image: string;
  thumbnail: string;
  statement: string;
}

export interface BlogItem extends BlogItemSummary {
  title: any[];
  published: string;
  content?: any[];
  furtherArticles?: BlogItemSummary[];
  furtherArticlesTags?: string[];
  meta: MetaData;
  tableOfContents: TableOfContent[];
  readingTime: number;
}

export interface TableOfContent {
  contentId: string;
  contentTitle: string;
}

export interface MetaData {
  description?: string;
  keywords?: string;
  title?: string;
}

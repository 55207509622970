import { Injectable } from '@angular/core';
import { RTParagraphNode } from '@prismicio/types';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { PrismicService } from './prismic.service';

const DYNAMIC_PAGE = 'page';

@Injectable()
export class DynamicPageService {
  constructor(private prismicService: PrismicService) {}

  getPageData(uid: string) {
    const graphQuery = `
    {
      page {
        ...pageFields
      }
    }
    `;

    return from(this.prismicService.client.getByUID(DYNAMIC_PAGE, uid, { graphQuery })).pipe(
      map((queryResponse) => {
        return this.handlePageData(queryResponse.data);
      })
    );
  }

  private handlePageData(data: Record<string, DynamicPage>): object {
    return {
      meta: {
        description: data['meta_description'],
        keywords: data['meta_keywords'],
        title: data['meta_title']
      },
      slices: data['body'],
      themeColor: data['theme']
    };
  }

  // private handleSlices()
}

export interface DynamicPage {
  meta: MetaData;
  title: RTParagraphNode;
}

export interface MetaData {
  description?: string;
  keywords?: string;
  title?: string;
}

import { Component, Input, OnInit } from '@angular/core';
import { ButtonType, Footer } from '../prismic/services/navigation-data.service';

@Component({
  selector: 'ulz-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() footer?: Footer;

  buttonType = ButtonType;

  constructor() {}

  ngOnInit(): void {}

  isHorizontal(section: any): boolean {
    return section.primary.grid === true;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilledLinkToDocumentField, FilledLinkToMediaField, FilledLinkToWebField, LinkField, LinkType } from '@prismicio/types';
import { TranslationService } from '../../../services/translation.service';
import { ButtonType } from '../../prismic/services/navigation-data.service';

@Component({
  selector: 'ulz-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss']
})
export class ButtonLinkComponent implements OnInit {
  @Input() link?: LinkField;
  @Input() ctaText = 'Learn more';
  // @Input() fragment?: string;
  @Input() skipLocationChange = false;
  @Input() showIcon = false;
  // @Input() underline = false;
  @Input() matButton = false;
  @Input() color: 'primary' | 'accent' = 'primary';
  @Input() buttonType: ButtonType = ButtonType.default;
  @Input() before = false;
  @Input() empty = false;
  @Input() isDialog = false;

  _fragment?: string;
  get fragment(): string | undefined {
    return this._fragment;
  }
  @Input() set fragment(value: string | undefined) {
    this._fragment = value;

    if (value === 'form') {
      this.isDialog = true;
    }
  }

  routerLink?: string;
  hrefLink?: string;
  target: string | undefined = '';

  buttonTypeEnum = ButtonType;

  constructor(public dialog: MatDialog, private translationService: TranslationService) {}

  ngOnInit(): void {
    switch (this.link?.link_type) {
      case LinkType.Document:
        this.setDocument(this.link as FilledLinkToDocumentField);
        break;
      case LinkType.Web:
        this.setHref(this.link as FilledLinkToWebField);
        break;
      case LinkType.Media:
        this.setMedia(this.link as FilledLinkToMediaField);
    }
  }

  private setDocument(link: FilledLinkToDocumentField) {
    const langCode = this.translationService.language || 'en';

    const uidToUrl = (link.uid || '').replace(/\./g, '/');
    const standardRouterLink = `/${langCode}/${uidToUrl}`.replace('home', '').replace('/en', '');

    switch (link.type) {
      case 'placeholder':
        this.routerLink = '/placeholder/' + uidToUrl;
        break;
      case 'page':
        this.routerLink = standardRouterLink;
        break;
      default:
        this.routerLink = standardRouterLink;
    }
  }

  private setHref(link: FilledLinkToWebField) {
    this.hrefLink = link.url;
    this.target = link.target;
  }

  private setMedia(link: FilledLinkToMediaField) {
    this.hrefLink = link.url;
    this.target = '_blank';
  }
}

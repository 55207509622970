import { Component, Input, OnInit } from '@angular/core';
import { LinkField, LinkType } from '@prismicio/types';
import { ButtonType, Navbar } from '../prismic/services/navigation-data.service';

@Component({
  selector: 'ulz-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  @Input() navbar?: Navbar;

  homeLink: LinkField = {
    link_type: LinkType.Document,
    uid: ''
  };

  isMobileMenuOpen = false;
  buttonType = ButtonType;

  constructor() {}

  ngOnInit(): void {}
}

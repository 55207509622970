import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

@Directive({
  selector: '[ulzScrollLock]'
})
export class ScrollLockDirective implements OnInit, OnDestroy {
  // @Input() cssClassName: string;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    disableBodyScroll(this.el.nativeElement);
  }

  ngOnDestroy(): void {
    clearAllBodyScrollLocks();
  }
}

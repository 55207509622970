import { Injectable } from '@angular/core';
import { Client, createClient } from '@prismicio/client';
import { environment } from '../../../../environments/environment';

@Injectable()
export class PrismicService {
  client: Client;

  constructor() {
    this.client = createClient(environment.prismic.url);
  }
}

<ng-container *ngIf="!empty">
  <ng-container *ngIf="!matButton">
    <a *ngIf="routerLink" [routerLink]="routerLink" [fragment]="fragment" [skipLocationChange]="skipLocationChange">
      <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
    </a>

    <a *ngIf="hrefLink" [href]="hrefLink" [target]="target" rel="noopener">
      <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
    </a>
  </ng-container>

  <ng-container *ngIf="matButton">
    <ng-container [ngSwitch]="buttonType" *ngIf="routerLink">
      <a
        *ngSwitchCase="buttonTypeEnum.default"
        mat-button
        [routerLink]="routerLink"
        [fragment]="fragment"
        [skipLocationChange]="skipLocationChange"
      >
        <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
      </a>
      <a
        *ngSwitchCase="buttonTypeEnum.primary"
        mat-flat-button
        [color]="color"
        [routerLink]="routerLink"
        [fragment]="fragment"
        [skipLocationChange]="skipLocationChange"
      >
        <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
      </a>
      <a
        *ngSwitchCase="buttonTypeEnum.secondary"
        mat-stroked-button
        [color]="color"
        [routerLink]="routerLink"
        [fragment]="fragment"
        [skipLocationChange]="skipLocationChange"
      >
        <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
      </a>
    </ng-container>

    <ng-container [ngSwitch]="buttonType" *ngIf="hrefLink">
      <a *ngSwitchCase="buttonTypeEnum.default" mat-button color="accent" [href]="hrefLink" [target]="target" rel="noopener">
        <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
      </a>
      <a *ngSwitchCase="buttonTypeEnum.primary" mat-flat-button [color]="color" [href]="hrefLink" [target]="target" rel="noopener">
        <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
      </a>
      <a *ngSwitchCase="buttonTypeEnum.secondary" mat-stroked-button [color]="color" [href]="hrefLink" [target]="target" rel="noopener">
        <ng-container [ngTemplateOutlet]="buttonContent"></ng-container>
      </a>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #buttonContent>
  <div class="d-flex justify-content-start align-items-center gap-2">
    <mat-icon *ngIf="showIcon && before" color="accent">arrow_forward</mat-icon>
    <span [innerHtml]="ctaText"></span>
    <mat-icon *ngIf="showIcon && !before" color="accent">arrow_forward</mat-icon>
  </div>
</ng-template>

<ng-container *ngIf="empty">
  <a *ngIf="routerLink" [routerLink]="routerLink" [fragment]="fragment" [skipLocationChange]="skipLocationChange">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>

  <a *ngIf="hrefLink" [href]="hrefLink" [target]="target" rel="noopener">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </a>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<div *ngIf="footer" class="py-5 bg-orange">
  <div class="container">
    <div class="row justify-content-between gap-4 bg-accent color-white p-5">
      <div class="col-12 col-md-5 col-xl-3">
        <h4 class="f-700 pb-4">{{ footer.contact_title }}</h4>

        <ng-container *ngFor="let item of footer.contact">
          <ulz-button-link [link]="item.link" [empty]="true">
            <div class="section-button" [innerHtml]="item.button_text | renderHtml"></div>
          </ulz-button-link>
        </ng-container>
      </div>

      <div class="col-12 col-md-5 col-xl-3">
        <h4 class="f-700 pb-4">{{ footer.opening_hours_title }}</h4>

        <ng-container *ngFor="let item of footer.opening_hours">
          <div class="d-flex justify-content-between">
            <p class="m-0">{{ item.day }}</p>
            <p class="m-0">{{ item.hours }}</p>
          </div>
        </ng-container>
      </div>

      <div class="col-12 col-md-5 col-xl-3">
        <h4 class="f-700 pb-4">{{ footer.section_title }}</h4>

        <ng-container *ngFor="let item of footer.footer_buttons">
          <ulz-button-link [link]="item.link" [empty]="true">
            <div class="section-button" [innerHtml]="item.button_text | renderHtml"></div>
          </ulz-button-link>
        </ng-container>
      </div>
    </div>
    <div class="pt-4 d-flex flex-wrap gap-4">
      <ng-container *ngFor="let item of footer.bottom_links">
        <ulz-button-link
          [ctaText]="item.button_text"
          [link]="item.link"
          [matButton]="true"
          [buttonType]="buttonType.default"
          [fragment]="item.anchor_tag"
        ></ulz-button-link>
      </ng-container>
    </div>
  </div>
</div>

import { Environment } from './environment.model';
// @ts-ignore
import * as gitRef from './versions.json';

export const environment: Environment = {
  production: false,
  baseUrl: 'https://www.therapie-ulzheimer.com',
  sentry: {},
  prismic: {
    url: 'https://therapie-ulzheimer.prismic.io/api/v2',
    previewKey: 'therapie-ulzheimer.prismic.io'
  },
  git: {
    ref: gitRef
  },
  // phpUrl: 'http://localhost:8090/form.php'
  phpUrl: 'php/form.php'
};

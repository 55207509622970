import { ViewportScroller } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { delay, filter } from 'rxjs/operators';
import { NavigationData, NavigationDataService } from './modules/prismic/services/navigation-data.service';
import { ScrollService } from './services/scroll.service';

@Component({
  selector: 'ulz-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  navigationData?: NavigationData;

  constructor(
    private scrollService: ScrollService,
    private navigationDataService: NavigationDataService,
    private readonly router: Router,
    private viewportScroller: ViewportScroller
  ) {
    this.navigationDataService.getNavigationData().subscribe((data) => {
      this.navigationData = data;
    });

    this.subscribeToRouterEvents();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.scrollService.scrollY.next(window.scrollY);
  }

  subscribeToRouterEvents(): void {
    this.router.events
      .pipe(
        filter((e: any): e is Scroll => e instanceof Scroll),
        delay(0)
      )
      .subscribe((e: any) => {
        if (e.position) {
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          try {
            const element = document.querySelector(`#${e.anchor}`);
            if (element) {
              element.scrollIntoView({ behavior: 'auto', block: 'start' });
            }
          } catch (e) {}
        }
      });
  }
}

<div [class.show]="navbar" ulzNavbar class="navigation-container">
  <div class="container d-flex justify-content-between align-items-center">
    <ulz-button-link *ngIf="navbar" [empty]="true" [link]="homeLink" (click)="isMobileMenuOpen = false">
      <img class="logo" [src]="navbar.logo.url" [alt]="navbar.logo.url" />
    </ulz-button-link>

    <div class="d-none d-lg-flex gap-3 align-items-center">
      <ng-container [ngTemplateOutlet]="buttons"></ng-container>
    </div>

    <div class="d-lg-none">
      <button mat-icon-button (click)="isMobileMenuOpen = !isMobileMenuOpen">
        <mat-icon>menu</mat-icon>
      </button>
    </div>
  </div>

  <div class="mobile-menu d-lg-none" [class.open]="isMobileMenuOpen">
    <div ulzScrollLock *ngIf="isMobileMenuOpen"></div>

    <div class="container h-100 d-flex flex-column justify-content-center align-items-stretch align-items-sm-center gap-3">
      <ng-container [ngTemplateOutlet]="buttons"></ng-container>
    </div>
  </div>
</div>

<ng-template #buttons>
  <ng-container *ngFor="let item of navbar?.buttons">
    <ulz-button-link
      [ctaText]="item.button_text"
      [link]="item.link"
      [matButton]="true"
      [buttonType]="item.button_type ? buttonType.primary : buttonType.default"
      [fragment]="item.anchor_tag"
      (click)="isMobileMenuOpen = false"
    ></ulz-button-link>
  </ng-container>
</ng-template>

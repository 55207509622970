import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { DynamicPageService } from './services/dynamic-page.service';
import { NavigationDataService } from './services/navigation-data.service';
import { NewsService } from './services/news.service';
import { PrismicService } from './services/prismic.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    PrismicService,
    {
      provide: new InjectionToken<string>('API_TOKEN'),
      useFactory: (cookieService: CookieService) => {
        const previewCookie = cookieService.get('io.prismic.preview');
        if (!previewCookie) {
          return;
        }
        return JSON.parse(previewCookie)?.[environment.prismic.previewKey]?.['preview'];
      },
      deps: [CookieService]
    },
    CookieService,
    DynamicPageService,
    NewsService
  ],
  exports: []
})
export class PrismicModule {
  static forRoot(): ModuleWithProviders<PrismicModule> {
    return {
      ngModule: PrismicModule,
      providers: [NavigationDataService]
    };
  }
}

import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ScrollService } from '../../../services/scroll.service';

@Directive({
  selector: '[ulzNavbar]'
})
export class NavbarDirective implements OnInit {
  lastPosition = 0;
  scrollTolerance = 200;

  constructor(private el: ElementRef, private scrollService: ScrollService, private renderer: Renderer2) {}

  ngOnInit() {
    this.scrollService.scrollY.subscribe((scroll) => {
      if (scroll - this.scrollTolerance > this.lastPosition) {
        this.renderer.addClass(this.el.nativeElement, 'shrink');
        this.lastPosition = scroll;
      }

      if (scroll + this.scrollTolerance < this.lastPosition) {
        this.renderer.removeClass(this.el.nativeElement, 'shrink');
        this.lastPosition = scroll;
      }

      if (scroll > 40) {
        this.renderer.addClass(this.el.nativeElement, 'shrink');
      } else {
        this.renderer.removeClass(this.el.nativeElement, 'shrink');
      }
    });
  }
}

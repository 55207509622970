import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { NavbarDirective } from './directives/navbar.directive';
import { NavigationComponent } from './navigation.component';

@NgModule({
  declarations: [NavigationComponent, NavbarDirective],
  exports: [NavigationComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, RouterModule, SharedModule]
})
export class NavigationModule {}
